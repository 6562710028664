import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@apollo/react-hooks';
import { NextSeo, DefaultSeo } from 'next-seo';
import Head from 'next/head';
import getConfig from 'next/config';
import PropTypes from 'prop-types';

import { DataContext } from '@ccg/core';
import { i18n } from '../../../i18n';

import entryQuery from './graphql/entry.graphql';
import categoryQuery from './graphql/category.graphql';

import { removeSpecialCharacters } from '../../../helper';

const {
  publicRuntimeConfig: { ACTIVE_SITE_HANDLE }
} = getConfig();

const Seo = ({ data, defaults }) => {
  const router = useRouter();
  const { asPath } = router;

  const { entryTitle, subline, seo } = data;

  const {
    siteDomain,
    data: { entry }
  } = useContext(DataContext);

  const defaultSeo = {
    title: defaults.seoTitle,
    description: defaults.seoDescription,
    social: {},
    advanced: {}
  };

  const {
    title,
    description,
    social: { facebook },
    advanced: { robots, canonical }
  } = seo || defaultSeo;

  // replace & / &amp; html entity from seo plugin
  const cleanTitle = title.replace(/&amp;/g, '&');

  const robotsCustom = robots && robots.length > 0 ? robots : 'index,follow';
  const fbImage = facebook && facebook.image ? [facebook.image] : null;
  const seoImage = fbImage || data.image || defaults.seoImage;

  const headTitle = {
    fork: 'FORK UNSTABLE MEDIA',
    fischerappelt: 'fischerAppelt',
    middleeast: 'fischerAppelt Middle East',
    qatar: 'fischerAppelt Qatar',
    puk: 'Philipp und Keuntje',
    ligalux: 'LIGALUX'
  };

  const siteIsEntry = typeof entry !== 'undefined';
  // eslint-disable-next-line no-nested-ternary
  const entryId = siteIsEntry ? (entry ? entry.id : null) : null;

  let languageQuery;
  if (router.route === '/category') {
    languageQuery = categoryQuery;
  } else {
    languageQuery = entryQuery;
  }

  const {
    options: { defaultLanguage, languages }
  } = i18n;

  const sites = languages.map(lang => {
    return `${ACTIVE_SITE_HANDLE}${lang.toUpperCase()}`;
  });

  const { data: languageData } = useQuery(languageQuery, {
    variables: { id: entryId, sites },
    skip: !siteIsEntry
  });

  const defaultLang = ((languageData && languageData.languages) || []).find(lang => {
    return lang.language === defaultLanguage;
  });

  return (
    <>
      {languageData && languageData.languages.length > 1 ? (
        <Head>
          {languageData.languages.map(lang => {
            if (lang.url) {
              return (
                <link
                  key={lang.language}
                  rel="alternate"
                  href={lang.url}
                  hrefLang={lang.language}
                />
              );
            }

            return null;
          })}

          {defaultLang && defaultLang.url ? (
            <link rel="alternate" href={defaultLang.url} hrefLang="x-default" />
          ) : null}
        </Head>
      ) : null}
      <DefaultSeo
        title={`${headTitle[ACTIVE_SITE_HANDLE]} | ${removeSpecialCharacters(
          defaults.seoTitle || entryTitle || ''
        )}`}
        description={removeSpecialCharacters(defaults.seoDescription || '')}
        additionalMetaTags={[
          {
            name: 'robots',
            content: 'index,follow'
          },
          {
            name: 'googlebot',
            content: 'index,follow'
          }
        ]}
        canonical={`${siteDomain}${asPath}`}
        openGraph={{
          type: 'website',
          url: `${siteDomain}${asPath}`,
          images:
            defaults.seoImage && defaults.seoImage[0]
              ? [
                  {
                    url: defaults.seoImage[0].url,
                    width: 1200,
                    height: defaults.seoImage[0].height,
                    alt: defaults.seoImage[0].alt
                  }
                ]
              : null
        }}
      />
      <NextSeo
        title={`${headTitle[ACTIVE_SITE_HANDLE]} | ${removeSpecialCharacters(
          cleanTitle || entryTitle || ''
        )}`}
        description={removeSpecialCharacters(description || subline || '')}
        additionalMetaTags={[
          {
            name: 'robots',
            content: robotsCustom
          },
          {
            name: 'googlebot',
            content: robotsCustom
          }
        ]}
        canonical={canonical}
        openGraph={{
          description: (facebook && facebook.description) || description || subline,
          images:
            seoImage && seoImage[0]
              ? [
                  {
                    url: seoImage[0].url,
                    width: 1200,
                    height: seoImage[0].height,
                    alt: seoImage[0].alt
                  }
                ]
              : null
        }}
        twitter={{
          cardType: 'summary_large_image'
        }}
      />
    </>
  );
};

Seo.propTypes = {
  data: PropTypes.shape().isRequired,
  defaults: PropTypes.shape().isRequired
};

export default Seo;
